<template>
  <div class="boxpa"> 
    <div class="flexcent frombox">
      <el-form ref="form" :model="form" :rules="formRules">
        <div v-for="(item, index) in inputlist" :key="index">
          <el-form-item :label="item.name" :prop="item.prop" >
            <el-input
              :type="item.type"
              v-model="form[item.prop]"
              :placeholder="item.placeholder"
              :maxlength="item.max"
              :rows="3"
            />
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="flexcent">
      <div class="flexcent regbtn" @click="updatePwd">{{submitting ? '正在提交': '修改密码'}}</div>
    </div>
  </div>
</template>

<script>
import { checkPassword } from "../../config/replace";

export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
       console.log(" validatePass2  ", value)
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.form.newpassword1) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
    }
    return {
      submitting: false,
      form: {
        userId: '',
        username: "", //用户名
        password: "", //密码
        newpassword1: "",
        // role: [1001], 
        tenantId: '2', //门户用户
      },
      formRules: {
        password: [
          { required: true, message: "请输入原密码!", trigger: "blur" },
          {
            min: 2,
            max: 30,
            message: "长度在 8 到 30 个字符!",
            trigger: "blur",
          },
          { validator: checkPassword, trigger: "blur" },
        ],
        newpassword1: [
          { required: true, message: "请输入新密码!", trigger: "blur" },
          {
            min: 2,
            max: 30,
            message: "长度在 8 到 30 个字符!",
            trigger: "blur",
          },
          { validator: checkPassword, trigger: "blur" },
        ],
        newpassword2: [
          { required: true, message: "请再次输入新密码!", trigger: "blur" },
          {
            min: 2,
            max: 30,
            message: "两次输入密码不一致!",
            trigger: "blur",
            validator: validatePass2,
          },
        ],
      },
      //   输入的数组
      inputlist: [
        {
          name: "原密码",
          prop: "password",
          placeholder: "请输入原密码",
          type: "password",
          max: 80,
        },
        {
          name: "新密码",
          prop: "newpassword1",
          placeholder: "请输入新密码",
          type: "password",
          max: 80,
        },
        {
          name: "重复新密码",
          prop: "newpassword2",
          placeholder: "请再次输入新密码",
          type: "password",
          max: 80,
        },
      ],
    };
  },

  created() {
    let checkLogin = this.until.checkLogin();
    if(!checkLogin) {
      let path = this.$route.path
      this.until.jump.call(this,'/tologin', "push", {title:"个人信息", redirect:path});
    } else {
      console.log(" userinfo", this.until.getUserInfo())
      this.form.username = this.until.getUserInfo().username;
      this.form.userId = this.until.getUserInfo().id;
    }
  },

  mounted() {
    // this.getlist();
     this.form.username = this.until.getUserInfo().username;
     this.form.userId = this.until.getUserInfo().id;
  },
  methods: {

    // 点击进行注册
    updatePwd() {
        this.$refs.form.validate((valid) => {
          console.log(" valid ", valid)
          if(this.form.password == this.form.newpassword1) {
            this.$message.error("新密码不能与旧密码相同！");
            return false
          }
          if (valid) {
            this.doUpdatePwd()
          } else {
            console.log('输入参数不正确，请重新输入！');
            return false
          }
        });
    },

    doUpdatePwd(){
      
      this.submitting = true
      this.$api.updatePwd(this.form).then((res) => {
        this.submitting=false
        if (res.code == 0) {
          this.$message.success("修改密码成功");
        } else {
           this.$message.error(res.msg);
        }
      }).catch((err)=>{
        this.$message.error("修改密码失败，稍后请重试！");
        this.submitting=false
        console.error(err)
      });
    }
  },
};

</script>

<style lang="less" >

.el-message {
  padding: 0.9375rem 1.25rem;
  font-size: 0.875rem;
}
</style>
<style lang="less"  scoped>

.boxpa {
  // background-color: #f9f9f9;
  // height: 100vh;
  // 首页
  .topres {
    box-shadow: 0 0 0.625rem #d1d1d1;
  }
  .frombox {
    width: 100%;
    /deep/ .el-form {
      width: 34.375rem;
      margin-top: 5rem;
      .el-form-item {
        display: flex;
        align-items: center;
        margin-bottom: 1.125rem;
        .el-form-item__label {
          width: 6.875rem;
          box-sizing: border-box;
          text-align: right;
          padding-right: 0.75rem;
          font-size: 0.875rem;
          color: #606266;
        }
        .el-form-item__content {
          flex: 1;
          .el-checkbox__inner {
            width: 1rem;
            height: 1rem;
            border: 0.0625rem solid #dcdfe6;
            border-radius: 0.125rem;
          }
          .el-checkbox__label {
            margin-left: 0.1875rem;
            font-size: 0.75rem;
          }
          .el-checkbox__inner::after {
            border: 0.125rem solid #fff;
            height: 0.5rem;
            width: 0.3rem;
            border-left: 0;
            left: 0.1875rem;
            border-top: 0;
          }
          input {
            height: 2rem;
            width: 27.5rem;
            padding: 0 1.25rem;
            border-radius: 0.3125rem;
            box-sizing: border-box;
            font-size: 0.875rem;
          }
          textarea {
            width: 27.5rem;
            padding: 0.625rem 1.25rem;
            border-radius: 0.3125rem;
            box-sizing: border-box;
            font-size: 0.875rem;
          }
        }
      }
    }
  }
  .regbtn {
    padding: 0 20px;
    width: 50px;
    height: 2.1875rem;
    color: #fff;
    background-color: #409eff;
    border-radius: 0.3125rem;
    font-size: 0.75rem;
  }
  .regbtn:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  .regbtn:active {
    background-color: #3a8ee6;
  }
}

// @media screen and (min-width: 1000px) {}

// @media screen and (max-width: 999px) {
// .boxpa {
//   background-color: #f9f9f9;
//   height: 100vh;
//   // 首页
//   .topres {
//     box-shadow: 0 0 0.625rem #d1d1d1;
//   }
//   .frombox {
//     width: 100%;
//     /deep/ .el-form {
//       width: 100%;
//       padding:  0 .9375rem;
//       box-sizing: border-box;
//       margin-top: 5rem;
//       .el-form-item {
//         display: flex;
//         align-items: center;
//         margin-bottom: 1.125rem;
//         .el-form-item__label {
//           width: 6.875rem;
//           box-sizing: border-box;
//           text-align: right;
//           padding-right: 0.75rem;
//           font-size: 0.875rem;
//           color: #606266;
//         }
//         .el-form-item__content {
//           flex: 1;
//           .el-checkbox__inner {
//             width: 1rem;
//             height: 1rem;
//             border: 0.0625rem solid #dcdfe6;
//             border-radius: 0.125rem;
//           }
//           .el-checkbox__label {
//             margin-left: 0.1875rem;
//             font-size: 0.75rem;
//           }
//           .el-checkbox__inner::after {
//             border: 0.125rem solid #fff;
//             height: 0.5rem;
//             width: 0.3rem;
//             border-left: 0;
//             left: 0.1875rem;
//             border-top: 0;
//           }
//           input {
//             height: 2rem;
//             width: 14rem;
//             padding: 0 1.25rem;
//             border-radius: 0.3125rem;
//             box-sizing: border-box;
//             font-size: 0.875rem;
//           }
//           textarea {
//             width: 14rem;
//             padding: 0.625rem 1.25rem;
//             border-radius: 0.3125rem;
//             box-sizing: border-box;
//             font-size: 0.875rem;
//           }
//         }
//       }
//     }
//   }
//   .regbtn {
//     width: 3.25rem;
//     height: 2.1875rem;
//     color: #fff;
//     background-color: #409eff;
//     border-radius: 0.3125rem;
//     font-size: 0.75rem;
//   }
//   .regbtn:hover {
//     cursor: pointer;
//     opacity: 0.7;
//   }
//   .regbtn:active {
//     background-color: #3a8ee6;
//   }
// }}
</style>
