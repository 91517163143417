export function checkPassword(rule, value, callback){
    var pattern = /^.*(?=.*\d)(?=.*[A-Za-z])(?=.*[!@#$%^&*?]).*$/;
    if (!value) {
      return callback(new Error('密码不能为空'))
    }

    // console.log(" callback", callback)
    // debugger
    setTimeout(() => {
      if (pattern.test(value)) {
        callback()
      } else {
        callback(new Error('密码必须包含大小写字母、数字和特殊字符'))
      }
    }, 100)
}

export function checkPhone(rule, value, callback){
  // var pattern =  /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/;
  // var pattern =  /^(0\d{2,3}-\d{7,8}(-\d{3,5}){0,1})|(((13[0-9])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{8})$/;
  // var pattern =  /^0\d{2,3}-\d{7,8}$/;
  // var t = pattern.test("010-87899844")
  // var pattern =  /^(0\d{2,3}-\d{7,8}(-\d{3,5}){0,1})$/;
  // var t = pattern.test("010-87899844")
  // var t2 = pattern.test("010-87899844-2323")
  
  //  var pattern =  /^(1\d{10})$/;
  var pattern =  /^((0\d{2,3}-\d{7,8}(-\d{3,5}){0,1}))|(1\d{10})$/;

  // var t = pattern.test("13111111111")
  // var t2 = pattern.test("010-87899844-2323")
  // // var pattern =  /^1[3456789]\d{1,2}$/
  // // var t = pattern.test("131")
  //   // var t = pattern.test("010-87899844")
  //   // var t = "tests"
  // console.log(" ---- pattern t : ", t)
  // console.log(" ---- pattern t2 : ", t2)
  console.log(" pattern ", pattern)
  console.log(" phone ", value)
  console.log(" match:", pattern.test(value))
  // console.log(" callback", callback)
  // debugger
  if (!value) {
    return callback(new Error('请输入电话号码'))
  }
  setTimeout(() => {
    if (pattern.test(value)) {
      callback()
    } else {
      callback(new Error('电话号码必须是区号-座机号或11手机号数字'))
    }
  }, 100)
}


